.office.background.container {
  position:absolute;
  left: -10px;
  max-width: 1300px;

  height: 600px;
  background-image: url("../images/office_background.png");
  div {
    .guide.column {
      height: 600px;

      .guide {
        position:absolute;
        bottom: 10px;
        right: 20px;
      }
    }
  }

}
