.dashboard.container {
  background-color: white;
  margin-top: 0px;
  .top.dashboard {
    padding-top: 15px;
  }
  .site {
    margin-left:15px;
  }
  .overall.dashboard {
    .date {
      .calendar {
        margin-left: 10px;
        margin-right: 10px;

        float: left;
        width: 30px;
        height: 40px;
        background-image: url('../images/calendar.png');
        background-repeat: no-repeat;
        .cal_text {
          display: inline-block;
          position:relative;
          top: 15px;
          text-align:center;
          width: 30px;
        }
      }
      .text {
        top: 2px;
      }
    }

    .funds {

      img {
        width: 50px;
        float:left;
        margin-right: 5px;
      }
    }
    .goal {

      img {
        width: 50px;
        float:left;
        margin-right: 5px;
      }
    }

    .trending {

      img {
        width: 50px;
        float:left;
        margin-right: 5px;
      }
      .ui.popup {
        min-width: 300px;
      }
    }
  }
}
