@import 'dashboard';

@import 'office';


.helpup {
  z-index: 1000;
}

.ui.right.floated {
  float: right;
}

.bold {
  font-weight: bold;
}

a.black {
  color:black;
}

.ui.modal.guide {
  ul {
    margin-left: 80px;
  }
}

.ui.dimmer.modals {
  background-color : rgba(0, 0, 0, 0.5);

}

.ui.accordion {
  .title {
    font-weight: bold;
  }
}

.ui.icon.right.floated {
  float: right;
}

.main.container {
  margin: 8px;

}
body {
  background-color: #6DBCC4;
  min-width: 1000px;
}

.ui.label.support {
  margin-top: 10px;
  cursor: pointer;
  .detail {
    margin-top:3px;
  }
}

.ui.spaced.item {
  padding-left: 5px;
  padding-right: 5px;
}


.popup.link {
  color: teal;
  text-decoration: underline;
}


.guide.message {
  position: absolute;
  right: 200px;
  bottom: 40px;
  width:400px;
  .guide {
    //position: absolute;
    height: 150px;
    float: left;
    margin-bottom: 0px;

  }
  .text {

  }
}
.splash.background.container {
  position:absolute;
  //left: -80px;
  width: 1000px;
  height: 600px;
  background-image: url("../images/office_background.png");

  .bubble {
    background-image: url("../images/splash_speech.png");
    position:absolute;
    width: 245px;
    height: 100px;
    img {
      float: left;
      margin: 8px;
      height: 50px;
    }
    .comment {
      position:absolute;
      left: 60px;
      margin: 8px;
      margin-right:15px;
    }
  }
  #bubble0 {
    top: 270px;
    left: 20px;
  }
  #bubble1 {
    top: 165px;
    left: 20px;
  }
  #bubble2 {
    top: 85px;
    left: 85px;
  }
  #bubble3 {
    top: 10px;
    left: 139px;
  }
  #bubble4 {
    background-image: url("../images/splash_speech_flipped.png");
    img {
      margin-left: 20px;
    }
    top: 30px;
    left: 410px;
  }
  #bubble5 {
    background-image: url("../images/splash_speech_flipped.png");
    img {
      margin-left: 20px;
    }
    top: 100px;
    left: 540px;
  }
  #bubble6 {
    background-image: url("../images/splash_speech_flipped.png");
    img {
      margin-left: 20px;
    }
    top: 175px;
    left: 570px;
  }
  #bubble7 {
    background-image: url("../images/splash_speech_flipped.png");
    img {
      margin-left: 20px;
    }
    top: 270px;
    left: 540px;
  }

}





[aria-hidden="true"] {
  display: none;
}

.extra.content.dark {
  color: black !important;
}

.ui.header .ui.label {
  margin-left: 0px;
  margin-top: 4px;
}

.scrolling.articles {
  padding: 10px;
  height:350px;
  position: relative;

}

.scrolling.profiles {
  padding: 10px;
  height:250px;
  position: relative;
}

.scrolling.groups {
  padding: 10px;
  height:250px;
  position: relative;
}


.max500 {
  height:500px;
  position: relative;
}

.ui.tag.label {
  margin-bottom: 4px;
}

.ui.label {
  margin-bottom: 4px;
}
.ui.fixed.sticky.dashboard {
  background: white;
}

.label.heat1 {
  opacity: .1
}
.label.heat2 {
  opacity: .2
}
.label.heat3 {
  opacity: .3
}
.label.heat4 {
  opacity: .4
}
.label.heat5 {
  opacity: .5
}
.label.heat6 {
  opacity: .6
}
.label.heat7 {
  opacity: .7
}
.label.heat8 {
  opacity: .8
}
.ui.label.red.opacity9 {
  opacity: .9
}
.label.heat10 {
  opacity: 1
}
